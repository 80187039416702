// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { Box, Card, CardContent, Container, Divider, Stack, Typography } from "@mui/material";

import { DomainName } from "../../../../components/DomainName";
import { SearchInput } from "../../../../components/SearchInput";
import { useDocumentTitle } from "../../../../context/hooks/setDocumentTitle";
import { parseTimestampNoMillisecond } from "../../../../utils";
import { AptosNamesSearch } from "../../../../utils/types";
import { ExpirationChip } from "../../../account/_components/ExpirationChip";
import { TargetAddress } from "./TargetAddress";

interface DomainNameInfoProps {
  domain: AptosNamesSearch;
}

export function DomainNameInfo({ domain }: DomainNameInfoProps): JSX.Element {
  useDocumentTitle(`${domain.domain}.apt - Aptos Names`);
  const { account } = useWallet();

  const targetAddressAccountConnected = account?.address === domain.registered_address;

  return (
    <>
      <Container>
        <Box sx={{ mt: "5rem" }}>
          <SearchInput />
          <Card sx={{ mt: "5rem" }}>
            <CardContent>
              <DomainName domain={domain.domain} text="Unavailable" />
              <hr />
              <Stack
                direction="column"
                spacing={4}
                mt={2}
                divider={<Divider variant="inset" orientation="horizontal" sx={{ mb: 0 }} />}
              >
                <Stack direction="column" spacing={2}>
                  <Typography noWrap variant="h6" fontWeight="bold">
                    Parent
                  </Typography>
                  <Box>
                    <Typography variant="h6">.apt</Typography>
                  </Box>
                </Stack>

                <TargetAddress
                  targetAddress={domain.registered_address ?? ""}
                  domainName={domain.domain}
                  targetAddressAccountConnected={targetAddressAccountConnected}
                />

                <Stack direction="column" spacing={2}>
                  <Typography noWrap variant="h6" fontWeight="bold">
                    Expiration Date
                  </Typography>
                  <Box display="flex" gap={4}>
                    <Typography variant="h6">
                      {parseTimestampNoMillisecond(domain.expiration)}
                    </Typography>
                    <ExpirationChip ansRecord={domain} />
                  </Box>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </>
  );
}
