// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Home, NavigateNext } from "@mui/icons-material";
import { Breadcrumbs as MUIBreadcrumbs, SxProps, Theme, useTheme } from "@mui/material";

import { Link } from "./Link";

export function Breadcrumbs({
  links = [],
  sx = {},
}: {
  sx?: SxProps<Theme>;
  links?: Array<{ label: string; to: string }>;
}) {
  const theme = useTheme();
  return (
    <MUIBreadcrumbs
      sx={{ color: theme.palette.text.disabled, ...sx }}
      aria-label="breadcrumb"
      separator={<NavigateNext fontSize="small" />}
    >
      <Link
        to="/"
        underline="hover"
        sx={{
          display: "flex",
          alignItems: "center",
          "&:hover": { color: theme.palette.text.primary },
        }}
        color="inherit"
      >
        <Home fontSize="small" />
      </Link>

      {links.map(({ label, to }) => (
        <Link
          key={to}
          to={to}
          underline="hover"
          sx={{
            display: "flex",
            alignItems: "center",
            "&:hover": { color: theme.palette.text.primary },
          }}
          color="inherit"
        >
          {label}
        </Link>
      ))}
    </MUIBreadcrumbs>
  );
}
