// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Box } from "@mui/material";
import React from "react";

import { Footer } from "./Footer";
import { Header } from "./Header";

interface LayoutProps {
  children: React.ReactNode;
}

export function AptosNamesLayout({ children }: LayoutProps) {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <Box
        sx={{
          backgroundColor: "transparent",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          flex: "1 1 0%",
        }}
      >
        <Header />
        <Box
          component="main"
          sx={{
            padding: 0,
            flex: "1 1 0%",
          }}
        >
          {children}
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}
