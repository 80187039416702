// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { GraphQLClient } from "graphql-request";

import { getSdk } from "../generated/indexer/sdk";

const INDEXER_GRAPHQL_ENDPOINT =
  process.env.REACT_APP_INDEXER_GRAPHQL ?? "http://localhost:3333/v1/graphql";

const indexerClient = new GraphQLClient(INDEXER_GRAPHQL_ENDPOINT);
export const indexer = getSdk(indexerClient);
