// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { InfoOutlined } from "@mui/icons-material";
import { Tooltip, Typography, useTheme } from "@mui/material";

export function ManageSectionTitle({ title, tooltip }: { title: string; tooltip: string }) {
  const theme = useTheme();
  return (
    <Typography
      color={theme.palette.text.disabled}
      variant="monoSubtitle"
      sx={{ display: "flex", alignItems: "center", gap: 1 }}
    >
      {title}
      <Tooltip title={tooltip}>
        <InfoOutlined fontSize="inherit" color="inherit" />
      </Tooltip>
    </Typography>
  );
}
