// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useDomainActions } from "../../../../context/hooks/useDomainActions";

interface RegisterButtonProps {
  domainName: string;
  years: number;
}

export function RegisterButton({ domainName, years }: RegisterButtonProps): JSX.Element {
  const { transactionInProcess, registerDomain } = useDomainActions();
  const { connected } = useWallet();
  const navigate = useNavigate();

  const onRegisterDomainClick = async () => {
    const success = await registerDomain({ domain: domainName, years });
    if (success) {
      navigate(`/account`);
    }
  };

  return (
    <Button
      variant="contained"
      disabled={!connected || transactionInProcess}
      size="large"
      onClick={onRegisterDomainClick}
      sx={{ minWidth: "8rem" }}
    >
      {transactionInProcess ? (
        <CircularProgress size={20} sx={{ color: "black" }} />
      ) : (
        "Register For 1 Year"
      )}
    </Button>
  );
}
