// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { AlertColor } from "@mui/material";
import { createContext, Dispatch, SetStateAction, useContext } from "react";

type SnackBarMessage = JSX.Element | string;

export interface SnackBarProps {
  isOpen: boolean;
  message: SnackBarMessage;
  severity: AlertColor;
}

export interface SnackBarContext {
  snackBar: SnackBarProps;
  setSnackBar: Dispatch<
    SetStateAction<{
      isOpen: boolean;
      message: SnackBarMessage;
      severity: AlertColor;
    }>
  >;
}

export const initialSnackBarContext = {
  snackBar: {
    isOpen: false,
    message: "" as SnackBarMessage,
    severity: "success" as AlertColor,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSnackBar: () => {},
};

export const SnackBarContext = createContext<SnackBarContext>(initialSnackBarContext);

export const useSnackBarContext = () => {
  const context = useContext(SnackBarContext);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!context) {
    throw new Error("useSnackBarContext must be used within a snackBarContext");
  }
  return context;
};
