// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

/*
3 characters - 20 APT
4 characters - 10 APT
5 characters - 5 APT
6+ characters - 1 APT
*/

export function getDomainCost(domainName: string) {
  switch (domainName.length) {
    case 3:
      return 20;
    case 4:
      return 10;
    case 5:
      return 5;
    default:
      return 1;
  }
}
