// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { Box, Container, Link, Stack, Typography, useTheme } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";

import { ReactComponent as LogoFull } from "../assets/svg/aptos_logo_icon.svg";
import { ReactComponent as DiscordLogo } from "../assets/svg/discord.svg";
import { ReactComponent as GithubLogo } from "../assets/svg/github.svg";
import { ReactComponent as LinkedInLogo } from "../assets/svg/linkedin.svg";
import { ReactComponent as MediumLogo } from "../assets/svg/medium.svg";
import { ReactComponent as TwitterLogo } from "../assets/svg/twitter.svg";
import { grey } from "../themes/colors/aptosColorPalette";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const socialLinks: Array<{ title: string; url: string; icon: any }> = [
  {
    title: "Git",
    url: "https://github.com/aptos-labs",
    icon: GithubLogo,
  },
  {
    title: "Discord",
    url: "https://discord.com/invite/aptosnames",
    icon: DiscordLogo,
  },
  {
    title: "Twitter",
    url: "https://twitter.com/aptoslabs/",
    icon: TwitterLogo,
  },
  {
    title: "Medium",
    url: "https://aptoslabs.medium.com/",
    icon: MediumLogo,
  },
  {
    title: "LinkedIn",
    url: "https://www.linkedin.com/company/aptoslabs/",
    icon: LinkedInLogo,
  },
];

export function Footer() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: "transparent",
        color: theme.palette.mode === "dark" ? grey[100] : "rgba(18,22,21,1)",
      }}
    >
      <Container maxWidth="xl" sx={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
        <Grid
          container
          spacing={{ xs: 4, md: 1 }}
          alignContent="center"
          alignItems="center"
          direction={{ xs: "column", md: "row" }}
        >
          <Grid xs="auto" container justifyContent="start">
            <Link
              color="inherit"
              href="https://aptoslabs.com/"
              target="_blank"
              sx={{ width: "3rem", mb: { xs: 2, md: 0 }, mr: { md: 2 } }}
            >
              <LogoFull />
            </Link>
          </Grid>
          <Grid xs="auto" container direction={{ xs: "column" }} justifyContent="start">
            <Typography sx={{ textAlign: { xs: "center", md: "left" } }} fontSize="0.8rem">
              © {new Date().getFullYear()}{" "}
              <Box component="span" sx={{ whiteSpace: "nowrap" }}>
                Aptos Labs
              </Box>
            </Typography>
            <Stack direction="row" spacing={1} justifyContent={{ xs: "center", md: "start" }}>
              <Link
                color="inherit"
                href="https://aptoslabs.com/privacy"
                target="_blank"
                sx={{ fontSize: "0.8rem" }}
              >
                Privacy
              </Link>
              <Link
                color="inherit"
                href="https://aptoslabs.com/terms"
                target="_blank"
                sx={{ fontSize: "0.8rem" }}
              >
                Terms
              </Link>
            </Stack>
          </Grid>
          <Grid
            xs="auto"
            sx={{ marginLeft: { xs: "0", md: "auto" } }}
            container
            justifyContent="end"
          >
            <Grid
              container
              justifyContent={{ xs: "center", md: "end" }}
              spacing={3}
              direction="row"
            >
              {socialLinks.map((link) => (
                <Grid key={link.title}>
                  <Link
                    color="inherit"
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={link.title}
                    width="26px"
                    sx={{ display: "block" }}
                  >
                    <SvgIcon component={link.icon} inheritViewBox />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
