// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { Box, Checkbox, TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { Navigate } from "react-router-dom";

import { GeneralTableHeaderCell } from "../../../components/GeneralTableHeaderCell";
import { useIsDesktop } from "../../../context/hooks/useIsDesktop";
import { RENEWAL_PAGE_LIMIT } from "../../../utils/constant";
import { AptosName } from "../../../utils/types";
import { AnsTableRow } from "./AnsTableRow";

export interface AnsTableState {
  mode: "include" | "exclude";
  records: Array<AptosName>;
}

type AnsTableProps = {
  ansRecords: Array<AptosName>;
  total: number;
  page: number;
  changePage: (i: number) => void;
  isLoading?: boolean;
  hideHeader?: boolean;
} & (
  | { variant?: "" }
  | { variant?: "with-left-space" }
  | {
      variant?: "with-toggle";
      setSelection: (selection: AnsTableState) => void;
      selection: AnsTableState;
      toggleOne: (name: AptosName) => void;
      toggleAll: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
      disabled?: boolean;
    }
);

export function AnsTable({
  ansRecords,
  page,
  changePage,
  total,
  hideHeader,
  ...props
}: AnsTableProps): JSX.Element {
  const { account } = useWallet();
  const isDesktop = useIsDesktop();

  if (!account) {
    return <Navigate to="/" />;
  }

  const showCheckboxColumn = props.variant;
  const showCheckbox = props.variant === "with-toggle";

  // This indicates if the table has a mix of selected and unselected domains
  const isIndeterminate =
    showCheckbox &&
    ((props.selection.mode === "exclude" &&
      props.selection.records.length > 0 &&
      props.selection.records.length < total) ||
      (props.selection.mode === "include" &&
        props.selection.records.length > 0 &&
        props.selection.records.length < total));

  const isAllSelected =
    showCheckbox &&
    ((props.selection.mode === "exclude" && props.selection.records.length === 0) ||
      (props.selection.mode === "include" && props.selection.records.length === total));

  const expiresOnHeader = (
    <>
      <GeneralTableHeaderCell header="Expires On" />
      <GeneralTableHeaderCell />
    </>
  );

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table
          aria-label="table"
          sx={{
            borderSpacing: {
              xs: 0,
              md: "0 8px",
            },
            tableLayout: "auto",
          }}
        >
          {isDesktop && (
            <colgroup>
              {showCheckboxColumn && <col style={{ width: "3%" }} />}
              <col style={{ width: "31%" }} />
              <col style={{ width: "15%" }} />
              <col />
              <col />
              <col style={{ width: "3%" }} />
            </colgroup>
          )}
          {!hideHeader && (
            <TableHead>
              <TableRow>
                {showCheckboxColumn && (
                  <GeneralTableHeaderCell sx={{ w: 1, whiteSpace: "nowrap" }}>
                    {showCheckbox && (
                      <Checkbox
                        color="secondary"
                        onChange={props.toggleAll}
                        indeterminate={isIndeterminate}
                        checked={isAllSelected}
                      />
                    )}
                  </GeneralTableHeaderCell>
                )}
                <GeneralTableHeaderCell sx={{ pl: !showCheckboxColumn ? 2 : 0 }} header="Name" />
                {!isDesktop && expiresOnHeader}
                <GeneralTableHeaderCell header={isDesktop ? "Target Address" : "Target"} />
                {isDesktop && expiresOnHeader}
                <GeneralTableHeaderCell />
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {ansRecords.map((domain) => {
              const isSelected = Boolean(
                showCheckbox &&
                  ((props.selection.mode === "exclude" &&
                    !props.selection.records.find((d) => d.domain === domain.domain)) ||
                    (props.selection.mode === "include" &&
                      props.selection.records.find((d) => d.domain === domain.domain))),
              );

              const extraProps =
                props.variant === "with-toggle"
                  ? {
                      variant: props.variant,
                      toggleOne: props.toggleOne,
                      selected: isSelected,
                    }
                  : { variant: props.variant };

              return (
                <AnsTableRow
                  key={`${domain.subdomain || ""}.${domain.domain}`}
                  ansRecord={domain}
                  {...extraProps}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {total > RENEWAL_PAGE_LIMIT && (
        <TablePagination
          page={page}
          component="div"
          count={total}
          rowsPerPage={RENEWAL_PAGE_LIMIT}
          onPageChange={(_, i) => {
            changePage(i);
          }}
          rowsPerPageOptions={[]}
        />
      )}
    </Box>
  );
}
