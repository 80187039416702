// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Stack, Typography } from "@mui/material";

import { getDomainCost } from "../../../../utils/getDomainCost";

interface DomainCostProps {
  domainName: string;
}

export function DomainCost({ domainName }: DomainCostProps) {
  return (
    <Stack justifyContent="center" pt={1}>
      <Typography variant="h5">{getDomainCost(domainName)} APT</Typography>
      <Typography variant="body1" sx={{ opacity: "0.5" }}>
        Domain Cost
      </Typography>
    </Stack>
  );
}
