// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Aptos, AptosConfig, MoveStructId } from "@aptos-labs/ts-sdk";

import { withResponseError } from "./client";

export const nodeUrl = process.env.REACT_APP_NODE_URL || "http://localhost:8080/v1";

export function getAccountResources(requestParameters: {
  address: string;
  ledgerVersion?: number;
}) {
  const config = new AptosConfig({ fullnode: nodeUrl });
  const client = new Aptos(config);
  const { address, ledgerVersion } = requestParameters;

  let ledgerVersionBig;
  if (ledgerVersion) {
    ledgerVersionBig = BigInt(ledgerVersion);
  }
  return withResponseError(
    client.getAccountResources({
      accountAddress: address,
      options: {
        ledgerVersion: ledgerVersionBig,
      },
    }),
  );
}

export function getAccountResource<T extends object = object>(requestParameters: {
  address: string;
  resourceType: MoveStructId;
  ledgerVersion?: number;
}): Promise<T> {
  const config = new AptosConfig({ fullnode: nodeUrl });
  const client = new Aptos(config);
  const { address, resourceType, ledgerVersion } = requestParameters;
  let ledgerVersionBig;
  if (ledgerVersion) {
    ledgerVersionBig = BigInt(ledgerVersion);
  }
  return withResponseError(
    client.getAccountResource<T>({
      accountAddress: address,
      resourceType,
      options: {
        ledgerVersion: ledgerVersionBig,
      },
    }),
  );
}
