// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Box, Container, Stack, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { CopyBlock, dracula } from "react-code-blocks";

import { Link } from "../../../components/Link";
import { useGlobalState } from "../../../context";
import { fontFamilies } from "../../../themes/theme";

const H1Section = ({ children }: { children: string }) => (
  <Typography component="h1" variant="h1" mb={3}>
    {children}
  </Typography>
);

const H2Section = ({ children }: { children: React.ReactNode }) => (
  <Typography component="h2" variant="h4" mb={3} mt={3}>
    {children}
  </Typography>
);

const H3Section = ({ children }: { children: React.ReactNode }) => (
  <Typography component="h3" variant="h5" mb={2}>
    {children}
  </Typography>
);

export function DocumentationPage() {
  const state = useGlobalState();
  const network = state.networkSupport;

  if (network != "mainnet" && network != "testnet") {
    return null;
  }

  return (
    <Container sx={{ pt: 12 }}>
      <Stack spacing={0}>
        <Box>
          <H1Section>Integrate Aptos Name Service into your app.</H1Section>
          <p>
            Aptos Naming Service allows members of the Aptos ecosystem to purchase a digital asset
            that replaces one's blockchain address with a domain name of their choosing. We view
            this as the place where one's Aptos Identity begins. For example, after creating an
            Aptos account, a user is granted a public address. Registering an Aptos Name will allow
            a user to interact with dApps as <Code>[Name].apt</Code> instead of as{" "}
            <Code>0xcd…984d</Code>. We developed the following Typescript SDK to help developers in
            the Aptos ecosystem to use the power of our naming service within their applications.
          </p>
        </Box>

        <Stack gap={2}>
          <Box>
            <H2Section>ANS SDK</H2Section>
            <p>
              This document describes how to use the ANS SDK, which is designed to enable developers
              in the Aptos ecosystem to use the power of Aptos Names within their applications. The
              ANS SDK fits within the broader Aptos TS SDK, which provides a set of tools for
              interacting with the Aptos blockchain. To get started with the SDK, follow{" "}
              <Link to="https://github.com/aptos-labs/aptos-ts-sdk#installation">
                the installation instructions
              </Link>
              .
            </p>

            <p>
              During development, you may find it helpful to be able test by minting names on the
              Testnet network. You can do so by visiting{" "}
              <Link to="testnet.aptosnames.com">the testnet version of this site</Link>.
            </p>
          </Box>
          <Box>
            <H3Section>Read APIs</H3Section>
            <p>
              These functions query the indexer. Each of these returns one or more name entries of
              the following shape:
            </p>
            <Codeblock>
              {`
Promise<undefined | {
  domain?: null | string;
  subdomain?: null | string;
  expiration_timestamp?: any; // a number represnting the epoch date in milliseconds
  is_primary?: null | boolean; // if the name is primary for the account
  owner_address?: null | string;
  registered_address?: null | string;
  token_standard?: null | string; // a string of 'v1' or 'v2'
}>
`.trim()}
            </Codeblock>

            <p>
              Furthermore, each function accepts an optional graphql pagination and graphql filter
              to further refine the returned names.
            </p>
            <ul>
              <li>
                <Link to="https://aptos-labs.github.io/aptos-ts-sdk/@aptos-labs/ts-sdk-0.0.8/classes/Aptos.html#getName">
                  getName
                </Link>
                : Fetch an individual name{" "}
              </li>
              <li>
                <Link to="https://aptos-labs.github.io/aptos-ts-sdk/@aptos-labs/ts-sdk-0.0.8/classes/Aptos.html#getAccountNames">
                  getAccountNames
                </Link>
                : Fetches all names (both domains and subdomains) for a given account.{" "}
              </li>
              <li>
                <Link to="https://aptos-labs.github.io/aptos-ts-sdk/@aptos-labs/ts-sdk-0.0.8/classes/Aptos.html#getAccountDomains">
                  getAccountDomains
                </Link>
                : Fetches all domains (excluding subdomains) for a given account.
              </li>
              <li>
                <Link to="https://aptos-labs.github.io/aptos-ts-sdk/@aptos-labs/ts-sdk-0.0.8/classes/Aptos.html#getAccountSubdomains">
                  getAccountSubdomains
                </Link>
                : Fetches all subdomains (excluding domains) for a given account
              </li>
              <li>
                <Link to="https://aptos-labs.github.io/aptos-ts-sdk/@aptos-labs/ts-sdk-0.0.8/classes/Aptos.html#getDomainSubdomains">
                  getDomainSubdomains
                </Link>
                : Fetches all subdomains for a given domain (excluding the top level domain). Note,
                the subdomains returned here include all subdomains under a domain, including
                subdomains that have been transferred to another account.{" "}
              </li>
            </ul>

            <p>
              These functions query the contract. Both <Code>getExpiration</Code> and{" "}
              <Code>getOwnerAddress</Code> can both be achieved via one of the indexer calls listed
              above. Use these functions is you specifically want to query the contract. The
              exception being <Code>getPrimaryName</Code>, which is the easiest way to get the
              primary name for a given account.
            </p>
            <ul>
              <li>
                <Link to="https://aptos-labs.github.io/aptos-ts-sdk/@aptos-labs/ts-sdk-0.0.8/classes/Aptos.html#getExpiration">
                  getExpiration
                </Link>
                : Fetches the expiration date for a given name. Returns a number in epoch
                milliseconds.
              </li>
              <li>
                <Link to="https://aptos-labs.github.io/aptos-ts-sdk/@aptos-labs/ts-sdk-0.0.8/classes/Aptos.html#getOwnerAddress">
                  getOwnerAddress
                </Link>
                : Fetches the owner address for a given name.
              </li>
              <li>
                <Link to="https://aptos-labs.github.io/aptos-ts-sdk/@aptos-labs/ts-sdk-0.0.8/classes/Aptos.html#getPrimaryName">
                  getPrimaryName
                </Link>
                : Fetches the primary name for a given account.
              </li>
            </ul>
          </Box>
          <Box>
            <H3Section>Write APIs</H3Section>
            <p>
              These functions use the ANS smart contract to send TXNs to the blockchain, creating
              new Aptos Names or modifying existing ones.
            </p>
            <ul>
              <li>
                <Link to="https://aptos-labs.github.io/aptos-ts-sdk/@aptos-labs/ts-sdk-0.0.8/classes/Aptos.html#registerName">
                  registerName
                </Link>
                : Registers a new domain or subdomain. The name can be inclusive or exclusive of the
                `.apt` suffix. When registering a name, you will need to provide an expiration
                policy, one of: `domain`, `subdomain:follow-domain`, or `subdomain:independent`. The
                later two policies apply only to subdomains and determin if the subdomain expires
              </li>

              <li>
                <Link to="https://aptos-labs.github.io/aptos-ts-sdk/@aptos-labs/ts-sdk-0.0.8/classes/Aptos.html#renewDomain">
                  rewnewDomain
                </Link>
                : Renews a domain name for one year. The signer must have sufficient funds and the
                domain name in question must expire in the next 6 months. Note, this only works with
                domains, not subdomains.
              </li>

              <li>
                <Link to="https://aptos-labs.github.io/aptos-ts-sdk/@aptos-labs/ts-sdk-0.0.8/classes/Aptos.html#setPrimaryName">
                  setPrimaryName
                </Link>
                : Sets the signers primary name for their account. The signer must be the owner of
                the name.
              </li>

              <li>
                <Link to="https://aptos-labs.github.io/aptos-ts-sdk/@aptos-labs/ts-sdk-0.0.8/classes/Aptos.html#setTargetAddress">
                  setTargetAddress
                </Link>
                : Sets the target address for the name. This can point to any address.
              </li>
            </ul>
          </Box>
          <Box>
            <H3Section>Example</H3Section>
            <p>
              The following example walks through how to perform common ANS operations with the SDK:
            </p>

            <p>{example_ts_sdk_1_description}</p>
            <Codeblock>{example_ts_sdk_1_code}</Codeblock>

            <p>{example_ts_sdk_2_description}</p>
            <Codeblock>{example_ts_sdk_2_code}</Codeblock>

            <p>{example_ts_sdk_3_description}</p>
            <Codeblock>{example_ts_sdk_3_code}</Codeblock>

            <p>{example_ts_sdk_4_description}</p>
            <Codeblock>{example_ts_sdk_4_code}</Codeblock>

            <p>{example_ts_sdk_5_description}</p>
            <Codeblock>{example_ts_sdk_5_code}</Codeblock>
          </Box>
        </Stack>
        <Box>
          <H2Section>Rest API</H2Section>
          <Stack spacing={3}>
            <Box>
              <H3Section>Convert Address to Primary Name</H3Section>
              <Codeblock>{example_api_address_to_primary_name}</Codeblock>
            </Box>
            <Box>
              <H3Section>Convert Address to Name</H3Section>
              <Codeblock>{example_api_address_to_name}</Codeblock>
            </Box>
            <Box>
              <H3Section>Convert Name to Address</H3Section>
              {/* TODO: include mainnet here */}
              <Codeblock>{example_api_name_to_address}</Codeblock>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

const example_api_address_to_primary_name = `
// Replace "0x1234...abcdef" with the address you want to lookup.
const address = "0x1234...abcdef";
const response = await fetch(\`https://www.aptosnames.com/api/\${network}/v1/primary-name/\${address}\`);
const { name } = await response.json();
`.trim();

const example_api_address_to_name = `
// Replace "0x1234...abcdef" with the address you want to lookup.
const address = "0x1234...abcdef";
const response = await fetch(\`https://www.aptosnames.com/api/\${network}/v1/name/\${address}\`);
const { name } = await response.json();
`.trim();

const example_api_name_to_address = `
// Replace "test" with your ANS name.
const name = "test";
const response = await fetch(\`https://www.aptosnames.com/api/\${network}/v1/address/\${name}\`);
const { address } = await response.json();
`.trim();

const example_ts_sdk_1_description = `
First, we need to instantiate the TS SDK. For our example, we will be using the localnet.
`.trim();
const example_ts_sdk_1_code = `
const config = new AptosConfig({ network: Network.LOCAL });
const localnet = new Aptos(config);

/**
 * We need to ensure we have an account with funds to work with.
 * This can come from a users wallet, we can generate an account, or
 * instantiate an account with a private key. For this example, we will
 * generate two accounts and fund them with 10apt each.
 */
const alice = Account.generate();
await localnet.fundAccount({
  accountAddress: alice.accountAddress.toString(),
  amount: 1_000_000_000,
});

const bob = Account.generate();
await localnet.fundAccount({
  accountAddress: bob.accountAddress.toString(),
  amount: 1_000_000_000,
});

/**
 * Let's define a simple helper function to generate a random strings.
 * Naming things is hard ;)
 */
const randomString = () => Math.random().toString().slice(2);
`;

const example_ts_sdk_2_description = `
Before we can register a name, we need to generate a transaction, sign and submit that transaction, and wait for the transaction to be finalized. We can create a helper function to do this for us, but we will write out all the steps here for clarity.
`.trim();

const example_ts_sdk_2_code = `
let txn: SingleSignerTransaction;
let pendingTxn: PendingTransactionResponse;

/**
 * We want to register a new name for alice. Note, we can include or
 * exclude the \`.apt\` suffix from the name.
 */
const name1 = \`\${randomString()}.apt\`;

txn = await localnet.registerName({
  sender: alice,
  name: name1,
  expiration: { policy: "domain" },
});

pendingTxn = await localnet.signAndSubmitTransaction({
  signer: alice,
  transaction: txn,
});

await localnet.waitForTransaction({ transactionHash: pendingTxn.hash });

/**
 * We can now query the name we just registered.
 */
const name1Owner = await localnet.getOwnerAddress({ name: name1 });
expect(name1Owner).toEqual(alice.accountAddress.toString());
`.trim();

const example_ts_sdk_3_description = `
Now that alice has their domain, what if they wanted to give a domain to their friend bob? Simple enough! Note, the subdomain comes before the top level domain.  Also note, we are not adding the \`.apt\` suffix to the name. It is optional for both domains and subdomains.
`.trim();
const example_ts_sdk_3_code = `
const subdomainOfName1 = \`\${randomString()}.\${name1}\`;

/**
 * Unlike domains that are registered for a fixed period of time, we can
 * specify the expiration policy of subdomains to act differently. We have
 * two options:
 *   1. \`subdomain:follow-domain\` - The subdomain will expire at the same time as the domain.
 *   2. \`subdomain:independent\` - The subdomain will expire at a fixed time.
 * For our example, we will have the subdomain follow its top level domain.
 *
 * Note: We are specifying the owner of the subdomain to be bob. Alice will
 * still have admin rights over the subdomain
 */
txn = await localnet.registerName({
  sender: alice,
  name: subdomainOfName1,
  expiration: { policy: "subdomain:follow-domain" },
  toAddress: bob.accountAddress.toString(),
  targetAddress: bob.accountAddress.toString(),
});

pendingTxn = await localnet.signAndSubmitTransaction({
  signer: alice,
  transaction: txn,
});

await localnet.waitForTransaction({ transactionHash: pendingTxn.hash });

const subdomainOfName1Owner = await localnet.getOwnerAddress({
  name: subdomainOfName1,
});
expect(subdomainOfName1Owner).toEqual(bob.accountAddress.toString());
`;

const example_ts_sdk_4_description = `
Time passes... The seasons change... Alice wants to check if their name has expired. We can query the contract to find out the expiration date of the name returned in seconds since the unix epoch.
`.trim();
const example_ts_sdk_4_code = `
const expirationFromContract = await localnet.getExpiration({ name: name1 });
if (expirationFromContract) {
  // expirationFromContract is in milliseconds since the unix epoch
  console.log(new Date(expirationFromContract));
}

/**
 * Let's try and renew alice's domain!
 *
 * Note, this function can fail if the name is not within 6 months of
 * expiration. Make sure to either check the expiration date or catch the
 * error.
 */
try {
  txn = await localnet.renewDomain({ sender: alice, name: name1 });
  pendingTxn = await localnet.signAndSubmitTransaction({
    signer: alice,
    transaction: txn,
  });
  await localnet.waitForTransaction({ transactionHash: pendingTxn.hash });
} catch (e) {
  console.log(
    "Looks like the name is not within 6 months of expiration. Try again later."
  );
}
`;

const example_ts_sdk_5_description = `
So far we have mainly been making transactions. Let's explore the read APIs a bit. Something to note, the examples above used localnet. The read APIs only work on Testnet and Mainnet.
`.trim();
const example_ts_sdk_5_code = `
const testnet = new Aptos(new AptosConfig({ network: Network.TESTNET }));
const nameFromIndexer = await testnet.getName({ name: "alice.apt" });
if (nameFromIndexer) {
  // nameFromIndexer.expiration_timestamp is in milliseconds since the unix epoch
  console.log(new Date(nameFromIndexer.expiration_timestamp));
}

/**
 * Let's explore fetching names a bit more. There are several functions
 * that let us query names. Note, each of these functions takes additional
 * filters if you want to narrow down the results. It also accepts
 * standard pagination parameters.
 */

/** This will return all subdomains and domains owned by alice */
const names = await testnet.getAccountNames({
  accountAddress: alice.accountAddress.toString(),
});
/** This will return all domains owned by alice */
const domains = await testnet.getAccountDomains({
  accountAddress: alice.accountAddress.toString(),
});
/** This will return all subdomains owned by alice. Note this will also return subdomains alice gives to others */
const subdomains = await testnet.getAccountSubdomains({
  accountAddress: alice.accountAddress.toString(),
});
/** This will return all subdomains created under the domain "alice.apt" regardless of who owns them */
const subdomainsOfDomain = await testnet.getDomainSubdomains({
  domain: "alice.apt",
});

/**
 * Perhaps we only want to fetch bob's names that are pointing to alice's
 * account. We can do that too!
 */
const bobsNamesPointingToAlice = await testnet.getAccountNames({
  accountAddress: bob.accountAddress.toString(),
  options: {
    where: {
      registered_address: { _eq: alice.accountAddress.toString() },
    },
  },
});
`.trim();

function Codeblock({ children }: { children: string }) {
  return (
    <CopyBlock
      text={children}
      language="ts"
      showLineNumbers={true}
      wrapLines
      theme={dracula}
      wrapLongLines={true}
      customStyle={{
        display: "grid",
        overflow: "scroll",
      }}
    />
  );
}

function Code(props: PropsWithChildren<{ className?: string }>) {
  return (
    <Box
      {...props}
      component="code"
      sx={(theme) => ({
        fontFamily: fontFamilies.mono,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.secondary,
      })}
    />
  );
}
