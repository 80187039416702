// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { AptosWalletAdapterProvider, NetworkName } from "@aptos-labs/wallet-adapter-react";
import { BloctoWallet } from "@blocto/aptos-wallet-adapter-plugin";
import { MartianWallet } from "@martianwallet/aptos-wallet-adapter";
import { MSafeWalletAdapter } from "@msafe/aptos-wallet-adapter";
import { OKXWallet } from "@okwallet/aptos-wallet-adapter";
import { PontemWallet } from "@pontem/wallet-adapter-plugin";
import { RiseWallet } from "@rise-wallet/wallet-adapter";
import { Network } from "aptos";
import { FewchaWallet } from "fewcha-plugin-wallet-adapter";
import { ReactNode, useContext, useMemo } from "react";

import { useGlobalState } from "./context";
import { SnackBarContext } from "./context/snackbar/context";

// A blocto app id is required to initialize the wallet adapter
// This is used when the real id from env is not available so that the website doesn't crash
const DUMMY_BLOCTO_APP_ID = "8d8ef56e-5f2e-6d13-b52e-95c4d5cf9622";
const APTOS_CONNECT_ID = "bf0a4b2e-b880-4277-bf99-eb0ca2fbbf59";

interface WalletProviderProps {
  children: ReactNode;
}

export function WalletsProvider({ children }: WalletProviderProps) {
  const state = useGlobalState();
  const { setSnackBar } = useContext(SnackBarContext);

  const network: NetworkName =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    NetworkName.Mainnet === state.networkSupport ? NetworkName.Mainnet : NetworkName.Testnet;

  const wallets = useMemo(() => {
    return [
      new OKXWallet(),
      new MartianWallet(),
      new PontemWallet(),
      new FewchaWallet(),
      new BloctoWallet({
        network,
        bloctoAppId: process.env.REACT_APP_BLOCTO_APP_ID || DUMMY_BLOCTO_APP_ID,
      }),
      new RiseWallet(),
      new MSafeWalletAdapter(),
    ];
  }, []);

  return (
    <AptosWalletAdapterProvider
      plugins={wallets}
      autoConnect={true}
      dappConfig={{
        aptosConnectDappId: APTOS_CONNECT_ID,
        network: network === NetworkName.Mainnet ? Network.MAINNET : Network.TESTNET,
      }}
      onError={(error) => {
        setSnackBar({
          isOpen: true,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          message: error,
          severity: "error",
        });
      }}
    >
      {children}
    </AptosWalletAdapterProvider>
  );
}
