// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

/* eslint-disable @typescript-eslint/no-non-null-assertion */

export function collapseHexString(hex: string, keepDigits = 10, isBalanced?: boolean): string {
  const hexStr = hex.toString();

  let digits = hexStr.split("x")[1];
  if (digits === undefined) {
    [digits] = hexStr.split("x");
  }

  if (digits!.length <= keepDigits) {
    return hexStr;
  }

  const keepDigitsLeft = isBalanced ? Math.ceil(keepDigits / 2 - 2) : Math.ceil(keepDigits / 2);
  const keepDigitsRight = Math.floor(keepDigits / 2);
  return `0x${digits!.slice(0, keepDigitsLeft)}..${digits!.slice(-keepDigitsRight)}`;
}
