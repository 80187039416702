// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

export enum ResponseErrorType {
  NOT_FOUND = "Not found",
  UNHANDLED = "Unhandled",
}

export type ResponseError =
  | { type: ResponseErrorType.NOT_FOUND; message?: string }
  | { type: ResponseErrorType.UNHANDLED; message: string };

export async function withResponseError<T>(promise: Promise<T>): Promise<T> {
  return await promise.catch((error) => {
    if (typeof error == "object" && "status" in error) {
      // This is a request!
      error = error as Response;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (error?.status === 404) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw { type: ResponseErrorType.NOT_FOUND };
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw {
      type: ResponseErrorType.UNHANDLED,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      message: error?.toString(),
    };
  });
}
