// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import * as Sentry from "@sentry/react";
import { StatsigClient } from "@statsig/js-client";
import { StatsigProvider } from "@statsig/react-bindings";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// Components
import { AptosNamingServiceRoutes } from "./AptosNamingServiceRoutes";
import { GlobalStateProvider, ProvideColorMode } from "./context";
import { SnackBarProvider } from "./context/snackbar/provider";
import { ConfirmProvider } from "./context/useConfirm";
import { WalletsProvider } from "./WalletsProvider";

Sentry.init({
  dsn: "https://a20019085ab549539ee2f78e4f82bdc4@o1162451.ingest.sentry.io/4503905329676288",
  integrations: [Sentry.browserTracingIntegration()],
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV == "production",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const statsigClient = new StatsigClient(
  process.env.REACT_APP_STATSIG_SDK_KEY ||
    "client-gQ2Zhz3hNYRf6CSVaczkQcZfK0yUBv5ln42yCDzTwbr" /* Dummy Key */,
  {},
);
statsigClient.initializeSync();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById("root")!);
window.addEventListener("load", () => {
  root.render(
    <React.StrictMode>
      <StatsigProvider client={statsigClient}>
        <QueryClientProvider client={queryClient}>
          <WalletsProvider>
            <GlobalStateProvider>
              <ProvideColorMode>
                <BrowserRouter>
                  <ConfirmProvider>
                    <SnackBarProvider>
                      <AptosNamingServiceRoutes />
                    </SnackBarProvider>
                  </ConfirmProvider>
                </BrowserRouter>
              </ProvideColorMode>
            </GlobalStateProvider>
          </WalletsProvider>
        </QueryClientProvider>
      </StatsigProvider>
    </React.StrictMode>,
  );
});
