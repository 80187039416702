// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { useSignAndSubmitTransaction } from "../../api/useSignAndSubmitTransaction";
import {
  BULK_MIGRATION_CONTRACT_ADDRESS,
  CONTRACT_ADDRESS,
  ONE_YEAR_IN_SECONDS,
} from "../../utils/constant";
import { getDomainCost } from "../../utils/getDomainCost";
import { getIP } from "../../utils/getIP";
import { isEligibleForFreeRenewal } from "../../utils/isEligibleForFreeRenewal";
import { useSendAnalytic } from "../../utils/sendAnalytic";
import { AptosName } from "../../utils/types";
import { useConfirm } from "../useConfirm";

export function useDomainActions() {
  const sendAnalytic = useSendAnalytic();
  const queryClient = useQueryClient();
  const { showConfirm } = useConfirm();
  const { submitTransaction, transactionInProcess } = useSignAndSubmitTransaction();

  const registerDomain = useCallback(
    async ({ domain, years }: { domain: string; years: number }) => {
      const { success, txn_hash } = await submitTransaction({
        successMessage: `Congratulations! You have successfully registered ${domain}.apt.`,
        function: `${CONTRACT_ADDRESS}::router::register_domain`,
        functionArguments: [domain, years * ONE_YEAR_IN_SECONDS, null, null],
      });
      void queryClient.invalidateQueries();
      sendAnalytic("registered_domain", {
        txn_hash,
        success,
        name: domain,
        ip: await getIP(),
      });
      return success;
    },
    [submitTransaction, transactionInProcess],
  );

  const registerSubdomain = useCallback(
    async ({ domain, subdomain }: { domain: AptosName; subdomain: string }) => {
      const domainName = domain.domain;
      const subdomainName = subdomain;
      const expiration_time_sec = domain.expiration.valueOf() / 1000;
      const expiration_policy = 1;
      const transferrable = true;
      const target_address = null;
      const to_address = null;

      const { success, txn_hash } = await submitTransaction({
        successMessage: `Congratulations! You have successfully registered ${subdomainName}.${domainName}.apt.`,
        function: `${CONTRACT_ADDRESS}::router::register_subdomain`,
        functionArguments: [
          domainName,
          subdomainName,
          expiration_time_sec,
          expiration_policy,
          transferrable,
          target_address,
          to_address,
        ],
      });
      void queryClient.invalidateQueries();
      sendAnalytic("registered_subdomain", {
        txn_hash,
        success,
        name: `${subdomainName}.${domainName}`,
        ip: await getIP(),
      });
      return success;
    },
    [submitTransaction, transactionInProcess],
  );

  const renewDomainName = useCallback(
    async (ansRecord: AptosName) => {
      const domainLength = ansRecord.domain.length;
      const cost = getDomainCost(ansRecord.domain);
      const confirmed = await showConfirm({
        title: "Renew Domain",
        description: `This name is ${domainLength} characters, so your renewal will cost ${cost} APT + gas. Please see our FAQ for more details on renewal pricing.`,
        yes: { children: "Renew", variant: "contained" },
        no: { children: "Cancel" },
      });
      if (!confirmed) {
        return;
      }
      const { success, txn_hash } = await submitTransaction({
        successMessage:
          "Congratulations! You have successfully renewed your Aptos Name. Please find your name in your Account page to view its extended expiration date.",
        function: `${CONTRACT_ADDRESS}::router::renew_domain`,
        functionArguments: [ansRecord.domain, ONE_YEAR_IN_SECONDS],
      });
      void queryClient.invalidateQueries();
      sendAnalytic("single_renew", {
        success,
        txn_hash,
        name: ansRecord.domain,
        ip: await getIP(),
      });
      return success;
    },
    [submitTransaction],
  );

  const setPrimary = useCallback(
    async (ansRecord: AptosName) => {
      const { domain, subdomain } = ansRecord;
      const { success } = await submitTransaction({
        successMessage:
          "Your primary name has been updated. You may now use this name to refer to your wallet across the Aptos ecosystem.",
        function: `${CONTRACT_ADDRESS}::router::set_primary_name`,
        functionArguments: [domain, subdomain || null],
      });
      void queryClient.invalidateQueries();
      return success;
    },
    [submitTransaction],
  );

  const clearTargetAddress = useCallback(
    async (domain: string, subdomain?: string) => {
      const res = await submitTransaction({
        successMessage: "The target address has been cleared.",
        function: `${CONTRACT_ADDRESS}::router::clear_target_addr`,
        functionArguments: [domain, subdomain || null],
      });
      void queryClient.invalidateQueries();
      return res;
    },
    [submitTransaction],
  );

  const clearPrimary = useCallback(async () => {
    const res = await submitTransaction({
      successMessage: "Your primary name has been cleared.",
      function: `${CONTRACT_ADDRESS}::router::clear_primary_name`,
      functionArguments: [],
    });
    void queryClient.invalidateQueries();
    return res;
  }, [submitTransaction]);

  const setTargetAddress = useCallback(
    async (ansRecord: AptosName, address: string) => {
      const { domain, subdomain } = ansRecord;
      const res = await submitTransaction({
        successMessage: `You successfully ${address ? "set" : "cleared"} your target address.`,
        function: address
          ? `${CONTRACT_ADDRESS}::router::set_target_addr`
          : `${CONTRACT_ADDRESS}::router::clear_target_addr`,
        functionArguments: address
          ? [domain, subdomain || null, address]
          : [domain, subdomain || null],
      });
      void queryClient.invalidateQueries();
      return res;
    },
    [submitTransaction],
  );

  const renewDomainNames = useCallback(
    async (domains: Array<AptosName>) => {
      const migrate = domains.filter(isEligibleForFreeRenewal).map((domain) => domain.domain);
      const renew = domains
        .filter((d) => !isEligibleForFreeRenewal(d))
        .map((domain) => domain.domain);
      const durations = renew.map(() => 365 * 24 * 60 * 60);

      const { success, txn_hash } = await submitTransaction({
        function: `${BULK_MIGRATION_CONTRACT_ADDRESS}::bulk::bulk_migrate_and_renew_domain`,
        functionArguments: [migrate, renew, durations],
      });
      void queryClient.invalidateQueries();
      sendAnalytic("bulk_renew", {
        success,
        txn_hash,
        ip: await getIP(),
        upgradedNames: migrate,
        names: renew,
      });

      return success;
    },
    [submitTransaction],
  );

  return {
    transactionInProcess,
    renewDomainName,
    setPrimary,
    clearPrimary,
    clearTargetAddress,
    setTargetAddress,
    registerDomain,
    registerSubdomain,
    renewDomainNames,
  };
}
