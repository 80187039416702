// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useMediaQuery, useTheme } from "@mui/material";

export function useIsDesktop() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return isDesktop;
}
