// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

export function makeFetchAll<T>(
  fetch: (offset: number) => Promise<Array<T>>,
): () => Promise<Array<T>> {
  return async () => {
    let items = [] as Array<T>;
    let crntPage = [] as Array<T>;

    do {
      crntPage = await fetch(items.length);
      items = items.concat(crntPage);
    } while (crntPage.length > 0);

    return items;
  };
}
