// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { Box, Button, Container, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useState } from "react";

import { Breadcrumbs } from "../../components/Breadcrumbs";
import { useDocumentTitle } from "../../context/hooks/setDocumentTitle";
import { useDomainActions } from "../../context/hooks/useDomainActions";
import { useDomains } from "../../context/hooks/useDomains";
import { useGetAccountOrphanedSubdomains } from "../../context/hooks/useGetAccountOrphanedSubdomains";
import { useConfirm } from "../../context/useConfirm";
import { getExpirationKeyDates } from "../../utils/getExpirationStatus";
import { isRenewable } from "../../utils/isRenewable";
import { AptosName } from "../../utils/types";
import { AnsTable } from "./_components/AnsTable";
import { NoDomainsCard } from "./_components/NoDomainsCard";

export function AccountPage(): JSX.Element {
  useDocumentTitle("My Account - Domains - Aptos Names");
  const { account, isLoading } = useWallet();
  const { renewDomainNames, transactionInProcess } = useDomainActions();
  const domainRes = useDomains({
    owner: account?.address ?? "",
    expiration_gte: getExpirationKeyDates("expired").valueOf(),
  });
  const subdomainRes = useGetAccountOrphanedSubdomains({
    owner: account?.address ?? "",
  });
  const { showConfirm } = useConfirm();

  const [selection, setSelection] = useState<{
    mode: "include" | "exclude";
    records: Array<AptosName>;
  }>({ records: [], mode: "include" });

  const toggleAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setSelection({
          mode: "exclude",
          records: [],
        });
      } else {
        setSelection({
          mode: "include",
          records: [],
        });
      }
    },
    [selection],
  );

  const toggleOne = useCallback(
    (name: AptosName) => {
      if (
        selection.records.find((r) => r.domain === name.domain && r.subdomain === name.subdomain)
      ) {
        setSelection({
          ...selection,
          records: selection.records.filter(
            (d) => !(d.domain === name.domain && d.subdomain === name.subdomain),
          ),
        });
      } else {
        setSelection({
          ...selection,
          records: selection.records.concat(name),
        });
      }
    },
    [selection],
  );

  const renewSelected = async () => {
    // Get the list of domains we are trying to migrate
    let domains: Array<AptosName> = [];
    if (selection.mode === "exclude") {
      domains = await domainRes.fetchAll();
      domains = domains.filter(
        (domain) =>
          !selection.records.find(
            (d) => d.domain === domain.domain && d.subdomain === domain.subdomain,
          ),
      );
    } else {
      domains = selection.records;
    }

    // Alert the user if some of the domains are already migrated, ask them if they want to continue
    const unRenewableDomains = domains.filter((d) => !isRenewable(d));
    const renewableDomains = domains.filter((d) => isRenewable(d));

    // Ensure the user has some domains to migrate
    if (renewableDomains.length === 0) {
      return showConfirm({
        title: "Note",
        description: "None of your selected domains are eligible for renewal.",
        yes: { children: "Cancel", variant: "contained" },
      });
    }

    // Notify the user that some of their domains are already migrated
    if (unRenewableDomains.length > 0) {
      const result = await showConfirm({
        title: "Renewal Eligibility",
        description: (
          <Stack gap={1}>
            <Typography>
              A name must be within 6 months of its expiration date to be eligible for renewal.
            </Typography>
            <div>
              <Typography>
                The following names you have selected are not eligible for renewal. Would you like
                to continue without them?{" "}
              </Typography>
              <ul>
                {unRenewableDomains.map((d) => (
                  <Typography component="li" key={d.domain}>
                    {d.domain}
                  </Typography>
                ))}
              </ul>
            </div>
          </Stack>
        ),
        no: { children: "Cancel", variant: "text", color: "inherit" },
        yes: { children: "Continue", variant: "contained" },
      });

      if (!result) return;
    }

    await renewDomainNames(renewableDomains);
    setSelection({ mode: "include", records: [] });
  };

  const pageLoading = isLoading || domainRes.loading;

  const disableRenewSelected =
    transactionInProcess ||
    (selection.mode === "include" && selection.records.length === 0) ||
    (selection.mode === "exclude" && selection.records.length === domainRes.total);

  return (
    <>
      <Container
        sx={{
          paddingTop: 2,
          px: {
            xs: 0,
            md: 2,
          },
        }}
      >
        <Breadcrumbs sx={{ px: { xs: 2, md: 0 } }} links={[{ label: "Account", to: "/account" }]} />

        <Typography sx={{ my: 6, px: { xs: 2, md: 0 } }} variant="h4">
          {account ? "Account" : "Please connect a wallet to view your account"}
        </Typography>

        {!pageLoading && !!domainRes.names.length && domainRes.total === 0 && <NoDomainsCard />}

        <Stack gap={2}>
          {!!domainRes.names.length && domainRes.total > 0 && (
            <>
              <AnsTable
                ansRecords={domainRes.names}
                changePage={domainRes.changePage}
                page={domainRes.page}
                total={domainRes.total}
                isLoading={domainRes.loading}
                variant="with-toggle"
                selection={selection}
                setSelection={setSelection}
                toggleAll={toggleAll}
                toggleOne={toggleOne}
              />

              <Box sx={{ display: "flex", gap: { xs: 2, md: 4 }, px: { xs: 2, md: 0 } }}>
                <Tooltip title="A name is eligible for renewal once it's within a 6-month window of its expiration date.">
                  <Button
                    disabled={disableRenewSelected}
                    variant="contained"
                    onClick={renewSelected}
                  >
                    Renew Selected
                  </Button>
                </Tooltip>
              </Box>
            </>
          )}

          {subdomainRes.total > 0 && (
            <Stack sx={{ mt: 2 }} gap={1}>
              <Typography sx={{ px: { xs: 2, md: 0 } }}>
                Names from other owners ({subdomainRes.total})
              </Typography>
              <AnsTable
                ansRecords={subdomainRes.subdomains}
                changePage={subdomainRes.changePage}
                page={subdomainRes.page}
                total={subdomainRes.total}
                isLoading={subdomainRes.loading}
              />
            </Stack>
          )}
        </Stack>
      </Container>
    </>
  );
}
