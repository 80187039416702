// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Button, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";

import { useDomainActions } from "../../../../../../context/hooks/useDomainActions";
import { parseTimestampNoMillisecond } from "../../../../../../utils";
import { getExpirationStatus } from "../../../../../../utils/getExpirationStatus";
import { isRenewable } from "../../../../../../utils/isRenewable";
import { AptosName } from "../../../../../../utils/types";
import { InGraceTooltip } from "./InGraceTooltip";
import { ManageSectionTitle } from "./ManageSectionTitle";
import { UpdateTargetAddressInput } from "./UpdateTargetAddressInput";

function ManageSection({
  title,
  tooltip,
  children,
}: React.PropsWithChildren<{ title: string; tooltip: string }>) {
  return (
    <Stack gap={1} alignItems="stretch" sx={{ width: "100%", maxWidth: "830px" }}>
      <ManageSectionTitle title={title} tooltip={tooltip} />
      <Stack flexDirection="row" gap={2} alignItems="center">
        {children}
      </Stack>
    </Stack>
  );
}

export function ManageTab({ ansRecord }: { ansRecord: AptosName; domain?: AptosName | null }) {
  const { clearPrimary, setPrimary, setTargetAddress, renewDomainName, transactionInProcess } =
    useDomainActions();

  const isSubdomain = !!ansRecord.subdomain;
  const isPetraName = ansRecord.domain === "petra";
  const isPrimary = ansRecord.is_primary;
  const renewable = isRenewable(ansRecord);
  const { domain: domainName, subdomain: subdomainName } = ansRecord;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const expiration_status = getExpirationStatus(ansRecord.expiration);
  const isInGrace = expiration_status === "in_grace";

  const togglePrimary = async () => {
    if (!isPrimary) {
      await setPrimary(ansRecord);
    } else {
      await clearPrimary();
    }
  };

  const updateTargetAddress = async (address: string) => {
    await setTargetAddress(ansRecord, address);
  };

  const onRenewClick = async () => {
    await renewDomainName(ansRecord);
  };

  const name = useMemo<string>(() => {
    return [subdomainName, domainName].filter((x) => x).join(".");
  }, [domainName, subdomainName]);

  return (
    <Stack gap={3} px={{ xs: 2, md: 0 }}>
      <ManageSection
        title="primary name"
        tooltip={
          ansRecord.is_primary
            ? `${name}.apt is the default name for this wallet across the Aptos ecosystem.`
            : `Make ${name}.apt the default name for this wallet across the Aptos ecosystem.`
        }
      >
        {isPrimary ? (
          <Typography>'{name}.apt' is set as your primary name.</Typography>
        ) : (
          <Typography>'{name}.apt' is not your primary name.</Typography>
        )}

        <InGraceTooltip inGrace={isInGrace}>
          <Button
            variant="contained"
            color={isPrimary ? "error" : "primary"}
            onClick={togglePrimary}
            disabled={transactionInProcess || isInGrace}
            sx={{ flexShrink: 0 }}
          >
            {isPrimary ? "Clear Primary Name" : "Set As Primary"}
          </Button>
        </InGraceTooltip>
      </ManageSection>

      <ManageSection
        title="expiration"
        tooltip="Your name is registered until its expiration date. To maintain ownership, you have the ability to renew a name's registration starting 6 months before and up to 30 days past expiry."
      >
        <Typography variant="stats">
          {isPetraName ? "-" : parseTimestampNoMillisecond(ansRecord.expiration)}
        </Typography>

        {!isSubdomain && (
          <Button
            variant="contained"
            onClick={onRenewClick}
            disabled={transactionInProcess || !renewable}
            sx={{ flexShrink: 0 }}
          >
            Renew Name for 1 Year
          </Button>
        )}
      </ManageSection>

      <ManageSection title="Target Address" tooltip="Set the address that the name points to.">
        <UpdateTargetAddressInput
          updateTargetAddress={updateTargetAddress}
          ansRecord={ansRecord}
          disabled={transactionInProcess}
        />
      </ManageSection>
    </Stack>
  );
}
