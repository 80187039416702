// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { EXPIRATION_CUTOFF } from "./constant";
import { AptosName } from "./types";

export function isEligibleForFreeRenewal(domain: AptosName): boolean {
  const cutoffDate = new Date(EXPIRATION_CUTOFF);

  return domain.expiration < cutoffDate;
}
