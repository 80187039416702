// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Aptos, AptosConfig } from "@aptos-labs/ts-sdk";
import { InputTransactionData, useWallet } from "@aptos-labs/wallet-adapter-react";
import { Link } from "@mui/material";
import { useContext, useState } from "react";

import { SnackBarContext } from "../context/snackbar/context";
import { collapseHexString } from "../utils/collapseHexString";
import { explorerURL } from "../utils/constant";
import { nodeUrl } from ".";

const config = new AptosConfig({
  fullnode: nodeUrl,
  clientConfig: {
    WITH_CREDENTIALS: false,
  },
});
const client = new Aptos(config);

export const useSignAndSubmitTransaction = () => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { signAndSubmitTransaction } = useWallet();
  const { snackBar, setSnackBar } = useContext(SnackBarContext);

  const [transactionInProcess, setTransactionInProcess] = useState<boolean>(false);

  const SuccessMessage = (transactionHash: string, successMessage?: string) => {
    const messages = [successMessage, "Transaction: "].filter((x) => x).join(" ");

    return (
      <>
        {messages}
        <Link
          href={`${explorerURL}/txn/${transactionHash}`}
          color="inherit"
          target="_blank"
          onClick={() => {
            setSnackBar({ ...snackBar, isOpen: false });
          }}
        >
          {collapseHexString(transactionHash)}
        </Link>
      </>
    );
  };

  const ErrorMessage = (errorMessage: string) => {
    if (!errorMessage) return null;
    return `Failed with error message "${errorMessage}". Please try again.`;
  };

  async function submitTransaction(
    args: { successMessage?: string } & InputTransactionData["data"],
  ): Promise<{ success: boolean; txn_hash?: string }> {
    setTransactionInProcess(true);
    try {
      const { successMessage, ...payload } = args;

      const data = (await signAndSubmitTransaction({ data: payload })) as { hash: string };
      if ("hash" in data) {
        const { success } = await client.waitForTransaction({ transactionHash: data.hash });
        if (!success) throw new Error("Transaction failed");

        setSnackBar({
          isOpen: true,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          message: SuccessMessage(data.hash, successMessage),
          severity: "success",
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        return { success: true, txn_hash: data.hash };
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const msg = typeof err === "object" ? err.message : err;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const message = ErrorMessage(msg);
      if (message) {
        setSnackBar({
          isOpen: true,
          message,
          severity: "error",
        });
      }
      return { success: false };
    } finally {
      setTransactionInProcess(false);
    }
    return { success: false };
  }

  return {
    submitTransaction,
    transactionInProcess,
    setTransactionInProcess,
  };
};
