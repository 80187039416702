// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";

export function Nav() {
  return (
    <Box
      sx={{
        display: { xs: "none", sm: "flex" },
        alignItems: "center",
        gap: { sm: 1, md: 4, lg: 8 },
        marginRight: { sm: 1, md: 2, lg: 4 },
      }}
    >
      <Button
        variant="nav"
        component={NavLink}
        to="/account"
        sx={{
          color: "inherit",
          fontSize: "1rem",
        }}
      >
        account
      </Button>
      <Button
        variant="nav"
        component={NavLink}
        to="/about/documentation"
        sx={{
          color: "inherit",
          fontSize: "1rem",
        }}
      >
        Docs
      </Button>
      <Button
        variant="nav"
        component={NavLink}
        to="/about/faq"
        sx={{
          color: "inherit",
          fontSize: "1rem",
        }}
      >
        FAQ
      </Button>
    </Box>
  );
}
