// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Stack, TableCell, Typography, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import React from "react";

import { useIsDesktop } from "../context/hooks/useIsDesktop";

type GeneralTableHeaderCellProps = React.PropsWithChildren<{
  header?: React.ReactNode;
  textAlignRight?: boolean;
  sx?: SxProps<Theme>;
  tooltip?: React.ReactNode;
}>;

export function GeneralTableHeaderCell({
  header,
  textAlignRight,
  sx = [],
  tooltip,
  children,
}: GeneralTableHeaderCellProps) {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const tableCellBackgroundColor = "transparent";
  const tableCellTextColor = theme.palette.text.secondary;

  return (
    <TableCell
      component="th"
      size={isDesktop ? "medium" : "small"}
      sx={[
        {
          textAlign: textAlignRight ? "right" : "left",
          background: tableCellBackgroundColor,
          color: tableCellTextColor,
          border: "none",
        },
        isDesktop ? {} : { pl: 0, pr: 0 },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {header && tooltip ? (
        <Stack
          direction="row"
          spacing={1}
          justifyContent={textAlignRight ? "flex-end" : "flex-start"}
        >
          <Typography variant="subtitle1">{header}</Typography>
          {tooltip}
        </Stack>
      ) : (
        <Typography variant="subtitle1">{header}</Typography>
      )}

      {children}
    </TableCell>
  );
}
