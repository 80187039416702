// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { RENEWAL_PAGE_LIMIT } from "../../utils/constant";
import { indexer } from "../../utils/indexer";
import { makeFetchAll } from "../../utils/makeFetchAll";
import { parseCurrentAptosName } from "../../utils/parseIndexerNames";
import { AptosName, CurrentAptosName } from "../../utils/types";

/**
 * This hook is used to get the orphaned subdomains of an account.
 * Orphaned in this case meaning that the same account does not own the domain
 */
export function useGetAccountOrphanedSubdomains({ owner }: { owner: string }) {
  const [page, setPage] = useState(0);

  const { data: subdomains = [], isLoading: isSubdomainLoading } = useQuery({
    queryKey: ["orphaned-subdomains", `page-${page}`, owner],
    placeholderData: (x: Array<AptosName> | undefined) => x,
    enabled: !!owner,
    staleTime: 60 * 1000,
    queryFn: async () => {
      try {
        const res = await indexer.get_account_orphaned_subdomains({
          limit: RENEWAL_PAGE_LIMIT,
          offset: page * RENEWAL_PAGE_LIMIT,
          owner_address: owner,
        });
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const names = (res.current_aptos_names ?? []) as Array<CurrentAptosName>;
        return names.map(parseCurrentAptosName);
      } catch (e) {
        console.warn(e);
        return [];
      }
    },
  });

  const { data: total = 0, isLoading: isTotalLoading } = useQuery({
    queryKey: ["orphaned-subdomains-count", "total", owner],
    placeholderData: (x: number | undefined) => x,
    enabled: !!owner,
    staleTime: 60 * 1000,
    queryFn: async () => {
      try {
        const totalRes = await indexer.get_account_orphaned_subdomains_count({
          owner_address: owner,
        });

        return totalRes.current_aptos_names_aggregate.aggregate?.count ?? 0;
      } catch (e) {
        console.warn(e);
        return 0;
      }
    },
  });

  const isLoading = isSubdomainLoading || isTotalLoading;

  function changePage(i: number) {
    if (isLoading) return;
    // Make sure the page is above 0 and below the total number of pages
    const clampedPage = Math.min(Math.max(0, i), Math.round(RENEWAL_PAGE_LIMIT / total));
    setPage(clampedPage);
  }

  const fetchAll = makeFetchAll(async (offset) => {
    const data = await indexer.get_account_orphaned_subdomains({
      owner_address: owner,
      offset,
      limit: RENEWAL_PAGE_LIMIT,
    });
    const names = data.current_aptos_names as Array<CurrentAptosName>;
    return names.map(parseCurrentAptosName);
  });

  return { total, loading: isLoading, subdomains, changePage, page, fetchAll };
}
