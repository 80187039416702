// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useWallet } from "@aptos-labs/wallet-adapter-react";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";

import { getAccountResource } from "../../api";
import { CONTRACT_ADDRESS } from "../../utils/constant";
import { CollectionCapabilityData, NamesRegistryData } from "../../utils/types";
import { GlobalStateContext, initialGlobalStateContext } from "./context";
import { WalletNetworkNotSupported } from "./WalletNetworkNotSupported";

interface GlobalContextProviderProps {
  children: React.ReactNode;
}

export const GlobalStateProvider: React.FC<GlobalContextProviderProps> = ({
  children,
}: GlobalContextProviderProps) => {
  const [state, setGlobalState] = useState(initialGlobalStateContext);
  const { network } = useWallet();
  const [walletNetworkSupported, setWalletNetworkSupported] = useState<boolean>(true);

  useEffect(() => {
    if (network?.name) {
      const walletNetwork = network.name.toLocaleLowerCase();
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const siteNetworkSupport = state.networkSupport.toLocaleLowerCase() ?? "";
      const walletNetworkCorrect = walletNetwork.includes(siteNetworkSupport);
      if (walletNetworkCorrect) {
        setWalletNetworkSupported(true);
      } else {
        setWalletNetworkSupported(false);
      }
    }
  }, [network]);

  useEffect(() => {
    const getTableHandle = async () => {
      const accountResource = await getAccountResource<NamesRegistryData>({
        address: CONTRACT_ADDRESS,
        resourceType: `${CONTRACT_ADDRESS}::domains::NameRegistryV1`,
      });
      const nameRegistryData = accountResource.data;
      return nameRegistryData.registry.handle;
    };

    const getCollectionCreatorAddress = async () => {
      const collectionCapabilityResource = await getAccountResource<CollectionCapabilityData>({
        address: CONTRACT_ADDRESS,
        resourceType: `${CONTRACT_ADDRESS}::token_helper::CollectionCapabilityV1`,
      });
      const collectionCreator = collectionCapabilityResource.data;
      return collectionCreator.capability.account;
    };

    Promise.all([getTableHandle(), getCollectionCreatorAddress()])
      .then((data) => {
        setGlobalState({
          ...state,
          tableHandle: data[0],
          collectionCreator: data[1],
        });
      })
      .catch((e) =>
        Sentry.captureException(`Error setting tableHandle and collectionCreator from api ${e}`),
      );
  }, []);

  return (
    <GlobalStateContext.Provider value={state}>
      {walletNetworkSupported ? children : <WalletNetworkNotSupported />}
    </GlobalStateContext.Provider>
  );
};
