// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { Autorenew, CheckCircleOutline, ChevronRight, MoreVert } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Stack } from "@mui/system";
import React, { Fragment, useMemo } from "react";
import * as RRD from "react-router-dom";

import { HashButton as AccountAddressButton } from "../../../components/AccountAddressButton";
import { Link } from "../../../components/Link";
import { LoadingModal } from "../../../components/LoadingModal";
import { useDomainActions } from "../../../context/hooks/useDomainActions";
import { useDomainSubdomainsCount } from "../../../context/hooks/useGetDomainSubdomainsCount";
import { useIsDesktop } from "../../../context/hooks/useIsDesktop";
import { parseTimestampNoMillisecond, parseTimestampShort } from "../../../utils";
import { getExpirationStatus } from "../../../utils/getExpirationStatus";
import { isRenewable } from "../../../utils/isRenewable";
import { AptosName } from "../../../utils/types";
import { InGraceTooltip } from "../[domain]/[subdomain]/manage/_components/InGraceTooltip";
import { ExpirationChip } from "./ExpirationChip";
import { PrimaryNameChip } from "./PrimaryNameChip";

function SubdomainCountChip({ ansRecord }: { ansRecord: AptosName }) {
  const { total } = useDomainSubdomainsCount({ domain: ansRecord.domain });
  const theme = useTheme();

  const { domain, subdomain } = ansRecord;

  const href = useMemo<string>(() => {
    return (
      "/" + ["account", domain, subdomain, "manage"].filter((x) => x).join("/") + "?tab=subdomains"
    );
  }, [ansRecord]);

  if (!total) return null;

  return (
    <Link
      to={href}
      onClick={(e) => {
        e.stopPropagation();
      }}
      sx={{
        color: theme.palette.text.disabled,
        textDecoration: "none",
        textTransform: "uppercase",
        fontSize: "10px",
        "&:hover": {
          textDecoration: "underline",
        },
      }}
    >
      <Typography variant="stats">
        {total} {total > 1 ? "subdomains" : "subdomain"}
      </Typography>
    </Link>
  );
}

function RowMenu({ ansRecord }: { ansRecord: AptosName }) {
  const isSubdomain = !!ansRecord.subdomain;
  const { renewDomainName, setPrimary, transactionInProcess } = useDomainActions();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    stopPropagation(event);
    setAnchorEl(event.currentTarget);
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const expirationStatus = getExpirationStatus(ansRecord.expiration);
  const isInGrace = expirationStatus === "in_grace";

  const stopPropagation = (e: any) => {
    if (
      e &&
      typeof e === "object" &&
      e.stopPropagation &&
      typeof e.stopPropagation === "function"
    ) {
      e.stopPropagation();
    }
  };

  const renewable = isRenewable(ansRecord);

  const handleClose = (e: any) => {
    stopPropagation(e);
    setAnchorEl(null);
  };

  const onRenewClick = async (e: any) => {
    stopPropagation(e);
    await renewDomainName(ansRecord);
    handleClose(e);
  };

  const onSetPrimaryClick = async (e: any) => {
    stopPropagation(e);
    await setPrimary(ansRecord);
    handleClose(e);
  };

  return (
    <Box>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <InGraceTooltip inGrace={isInGrace}>
          <MenuItem
            onClick={onSetPrimaryClick}
            disabled={ansRecord.is_primary || transactionInProcess || isInGrace}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <CheckCircleOutline fontSize="inherit" />
            </ListItemIcon>
            <Typography variant="inherit">Set as Primary</Typography>
          </MenuItem>
        </InGraceTooltip>

        {!isSubdomain && (
          <MenuItem onClick={onRenewClick} disabled={!renewable || transactionInProcess}>
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <Autorenew fontSize="inherit" />
            </ListItemIcon>
            <Typography variant="inherit">Renew Name For 1 Year</Typography>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
}

function Cluster({ children }: React.PropsWithChildren<object>) {
  const isDesktop = useIsDesktop();

  return (
    <Stack direction="row" justifyContent="flex-start" gap={isDesktop ? 2 : 1} alignItems="center">
      {children}
    </Stack>
  );
}

function TD({
  children,
  align,
  sx,
}: React.PropsWithChildren<{ align?: "left" | "right" | "center"; sx?: SxProps }>) {
  const theme = useTheme();
  const isDesktop = useIsDesktop();

  return (
    <TableCell
      size={isDesktop ? "medium" : "small"}
      align={align}
      sx={{
        // Inherit from caller
        ...sx,
        // Mobile
        [theme.breakpoints.down("md")]: {
          pl: 0,
          pr: 0,
          borderTop: `1px solid ${theme.palette.neutralShade.darker}}`,
          borderRadius: "0px!important",
        },
        // Desktop
        [theme.breakpoints.up("md")]: {
          "&:first-of-type": {
            borderTopLeftRadius: "8px!important",
            borderBottomLeftRadius: "8px!important",
          },
          "&:last-of-type": {
            borderTopRightRadius: "8px!important",
            borderBottomRightRadius: "8px!important",
          },
        },
      }}
    >
      {children}
    </TableCell>
  );
}

type AnsTableRowProps = {
  ansRecord: AptosName;
} & ( // Base table variant, no checkboxes or spacer columns
  | { variant?: "" }
  // Alternative variant, used to align the table with the checkbox column even
  // though †he table doesn't have checkboxes
  | { variant: "with-left-space" }
  // Variant with checkboxes
  | {
      variant: "with-toggle";
      toggleOne: (name: AptosName) => void;
      disabled?: boolean;
      selected?: boolean;
    }
);

export function AnsTableRow({ ansRecord, ...props }: AnsTableRowProps): JSX.Element {
  const { transactionInProcess } = useDomainActions();
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const navigate = RRD.useNavigate();
  const address = ansRecord.registered_address;
  const isSubdomain = !!ansRecord.subdomain;
  const isPetraName = ansRecord.domain === "petra";
  const expiration = ansRecord.expiration;

  const name = useMemo<string>(() => {
    const domain = ansRecord.domain;
    const subdomain = ansRecord.subdomain;
    return [subdomain, domain, "apt"].filter((x) => x).join(".");
  }, [ansRecord]);

  const shortName = useMemo<string>(() => {
    let shortened = name.replace(/\.apt$/, "");
    if (shortened.length > 10) {
      shortened = shortened.slice(0, 7) + "...";
    }
    return shortened;
  }, [name]);

  const href = useMemo<string>(() => {
    const { domain, subdomain } = ansRecord;
    return (
      "/" + ["account", domain, subdomain, "manage"].filter((x) => x).join("/") + "?tab=manage"
    );
  }, [ansRecord]);

  const showCheckboxColumn = props.variant;
  const showCheckbox = props.variant === "with-toggle";

  const toggleOne = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (showCheckbox) {
      props.toggleOne(ansRecord);
    }
  };

  const expiresOn = (
    <>
      <TD align="right" sx={{ width: 0, whiteSpace: "nowrap" }}>
        <Cluster>
          <Typography variant="stats" fontSize="0.856em">
            {isPetraName
              ? "-"
              : isDesktop
                ? parseTimestampNoMillisecond(expiration)
                : parseTimestampShort(expiration)}
          </Typography>
        </Cluster>
      </TD>
      <TD sx={{ p: 0 }}>
        <Cluster>
          <ExpirationChip size="small" ansRecord={ansRecord} />
        </Cluster>
      </TD>
    </>
  );

  return (
    <Fragment>
      <LoadingModal open={transactionInProcess} />
      <TableRow
        hover
        onClick={() => {
          navigate(href);
        }}
        sx={{
          cursor: "pointer",
          backgroundColor: {
            md: theme.palette.neutralShade.main,
          },
          "&.MuiTableRow-hover:hover": {
            backgroundColor:
              theme.palette.mode === "light"
                ? theme.palette.neutralShade.darker
                : theme.palette.neutralShade.lighter,
          },
        }}
      >
        {/* CHECKBOX */}
        {showCheckboxColumn && (
          <TD>
            <Checkbox
              checked={showCheckbox ? props.selected : false}
              disabled={showCheckbox ? props.disabled : true}
              onClick={toggleOne}
              sx={{ opacity: showCheckbox ? 1 : 0, cursor: showCheckbox ? "pointer" : "default" }}
              color="secondary"
            />
          </TD>
        )}

        {/* NAME */}
        <TD>
          <Cluster>
            <Stack
              sx={{
                flexShrink: 2,
                [theme.breakpoints.down("md")]: { pl: !showCheckboxColumn ? 2 : 0 },
              }}
            >
              <Link
                to={href}
                sx={{
                  color: theme.palette.text.primary,
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                <Typography sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }} variant="body1">
                  {isDesktop ? name : shortName}
                </Typography>
              </Link>

              {!isSubdomain && <SubdomainCountChip ansRecord={ansRecord} />}
            </Stack>
            <PrimaryNameChip ansRecord={ansRecord} size="small" />
          </Cluster>
        </TD>

        {!isDesktop && expiresOn}

        {/* ADDRESS SELECTOR */}
        <TD align="left">{address && <AccountAddressButton address={address} />}</TD>

        {/* EXPIRES ON */}
        {isDesktop && expiresOn}

        {/* OVERFLOW MENU */}
        <TD align="right">
          {isDesktop ? (
            <RowMenu ansRecord={ansRecord} />
          ) : (
            <Stack alignItems="center">
              <ChevronRight />
            </Stack>
          )}
        </TD>
      </TableRow>
    </Fragment>
  );
}
