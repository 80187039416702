// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { Box, Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { Breadcrumbs } from "../../../../../components/Breadcrumbs";
import { useDomainSubdomains } from "../../../../../context/hooks/useGetDomainsSubdomains";
import { useManageDomainData } from "../../../../../context/hooks/useManageDomainData";
import { ExpirationChip } from "../../../_components/ExpirationChip";
import { PrimaryNameChip } from "../../../_components/PrimaryNameChip";
import { ManageTab } from "./_components/ManageTab";
import { SubdomainsTab } from "./_components/SubdomainsTab";

enum TabValue {
  Manage = "manage",
  Subdomains = "subdomains",
}

interface DomainNameURLParams {
  domain: string;
  subdomain?: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  value: TabValue;
  currentValue: TabValue;
}

function TabPanel(props: TabPanelProps) {
  const { children, currentValue, value, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={currentValue !== value}
      id={`${currentValue}-tabpanel`}
      aria-labelledby={`s${currentValue}-tab`}
      {...other}
    >
      {currentValue === value && <Box sx={{ marginTop: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(tabLabel: string) {
  return {
    id: `${tabLabel}-tab`,
    "aria-controls": `${tabLabel}-tabpanel`,
  };
}

export function ManagePage() {
  const { account, connected } = useWallet();
  const { domain, subdomain } = useParams() as unknown as DomainNameURLParams;
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState<TabValue>(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (searchParams.get("tab") as TabValue) || TabValue.Manage,
  );

  const { domain: domainRes, subdomain: subdomainRes } = useManageDomainData(
    account?.address ?? "",
    domain,
    subdomain,
  );
  const subdomainsRes = useDomainSubdomains({ domain });

  const pageDomain = subdomainRes || domainRes;
  const isSubdomain = !!subdomainRes;

  const hasParent = subdomainRes && domainRes;
  const isParentOwner = domainRes?.owner_address === account?.address;

  const handleTabChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setSearchParams({ tab: newValue });
    setTabValue(newValue);
  };

  const currentTab = searchParams.get("tab") as TabValue | null;
  useEffect(() => {
    if (tabValue !== currentTab) {
      setTabValue(currentTab ?? TabValue.Manage);
    }
  }, [currentTab]);

  /**
   * Breadcrumbs
   * - We always have the base account crumb
   * - We might not have a base domain the case of an orphan subdomain
   *   (aka you are given a subdomain but without ownership of the parent domain)
   * - We might not have a subdomain in the case of a domain
   * - We might have both a domain and subdomain
   */
  const breadcrumbs = useMemo<
    Array<{
      label: string;
      to: string;
    }>
  >(() => {
    let data = [{ label: "Account", to: "/account" }];

    // No subdomain and we have a domain
    if (domainRes && !subdomainRes) {
      const d = domainRes;
      return data.concat([{ label: `${d.domain}.apt`, to: `/account/${d.domain}/manage` }]);
    }

    // We have a subdomain and optionally own the parent
    if (subdomainRes) {
      const s = subdomainRes;

      if (hasParent && isParentOwner) {
        data = data.concat({
          label: `${s.domain}.apt`,
          to: `/account/${s.domain}/manage`,
        });
      }

      return data.concat({
        label: `${s.subdomain}.${s.domain}.apt`,
        to: `/account/${s.domain}/${s.subdomain}/manage`,
      });
    }

    return data;
  }, [domainRes, subdomainRes, hasParent, isParentOwner]);

  const name = useMemo<string>(() => {
    const data = pageDomain;
    if (!data) return "";
    if (data.subdomain) return `${data.subdomain}.${data.domain}.apt`;
    return `${data.domain}.apt`;
  }, [pageDomain]);

  return (
    <Container sx={{ px: { xs: 0, md: 2 } }}>
      <Box sx={{ px: { xs: 2, md: 0 } }}>
        <Breadcrumbs links={breadcrumbs} />
        <Stack flexDirection="row" alignItems="center" justifyContent="flex-start" gap={3} my={6}>
          <Typography variant="h4">{name}</Typography>
          {pageDomain && <PrimaryNameChip ansRecord={pageDomain} />}
          {pageDomain && <ExpirationChip ansRecord={pageDomain} />}
        </Stack>

        {!connected && <Typography>Please connect your wallet to continue.</Typography>}
      </Box>

      {connected && (
        <Box>
          <Box sx={{ px: { xs: 2, md: 0 } }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Manage domain tabs"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab
                sx={{ padding: 0, minWidth: 0, marginRight: 2 }}
                label="Manage"
                value={TabValue.Manage}
                {...a11yProps("Settings")}
              />
              {!isSubdomain && (
                <Tab
                  sx={{ padding: 0 }}
                  value={TabValue.Subdomains}
                  label={
                    subdomainsRes.total > 0 ? `Subdomains (${subdomainsRes.total})` : "Subdomains"
                  }
                  {...a11yProps(
                    subdomainsRes.total > 0 ? `Subdomains (${subdomainsRes.total})` : "Subdomains",
                  )}
                />
              )}
            </Tabs>
          </Box>
          <TabPanel currentValue={tabValue} value={TabValue.Manage}>
            {pageDomain && <ManageTab ansRecord={pageDomain} domain={domainRes} />}
          </TabPanel>
          {!isSubdomain && (
            <TabPanel currentValue={tabValue} value={TabValue.Subdomains}>
              {pageDomain && (
                <SubdomainsTab
                  domain={pageDomain}
                  fetchAll={subdomainsRes.fetchAll}
                  changePage={subdomainsRes.changePage}
                  page={subdomainsRes.page}
                  subdomains={subdomainsRes.subdomains}
                  total={subdomainsRes.total}
                  isLoading={subdomainsRes.loading}
                />
              )}
            </TabPanel>
          )}
        </Box>
      )}
    </Container>
  );
}
