// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Ans_Record_LookupFragment } from "../generated/indexer/operations";
import { SubdomainExpirationPolicy } from "./constant";
import { AptosName, AptosNamesSearch, CurrentAptosName } from "./types";

export function parseCurrentAptosName(name: CurrentAptosName): AptosName {
  const expiration_timestamp = name.expiration_timestamp + "Z";
  const domain_expiration_timestamp = name.domain_expiration_timestamp + "Z";

  const isSubdomain = !!name.subdomain;
  const expirationPolicy = name.subdomain_expiration_policy as SubdomainExpirationPolicy;
  const expiration =
    isSubdomain && expirationPolicy === SubdomainExpirationPolicy.FollowsDomain
      ? domain_expiration_timestamp
      : expiration_timestamp;

  return {
    ...name,
    expiration_timestamp,
    domain_expiration_timestamp,
    expiration: new Date(expiration),
    token_standard: name.token_standard as "v1" | "v2",
  };
}

export function parseAptosNamesSearch(name: Ans_Record_LookupFragment): AptosNamesSearch {
  const expiration_timestamp = name.expiration_timestamp + "Z";

  return {
    ...name,
    expiration_timestamp,
    registered_address: name.registered_address || null,
    expiration: new Date(expiration_timestamp),
    token_standard: name.token_standard as "v1" | "v2",
  };
}
