// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Link as MUILink, LinkProps as MUILinkProps } from "@mui/material";
import { Link as RRDLink } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InternalLink: any = RRDLink;

interface LinkProps extends MUILinkProps {
  to?: string;
}

export function Link(props: LinkProps) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return <MUILink component={InternalLink} {...props} />;
}
