// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { ThemeProvider } from "@mui/system";
import React, { createContext, useContext } from "react";

import { ColorModeContext, useProvideColorMode } from "./ProvideColorMode.State";

interface ProvideColorModeProps {
  children: React.ReactNode;
}

const colorModeContext = createContext<ColorModeContext | null>(null);

export const ProvideColorMode: React.FC<ProvideColorModeProps> = ({
  children,
}: ProvideColorModeProps) => {
  const { toggleColorMode, theme } = useProvideColorMode();

  return (
    <colorModeContext.Provider value={{ toggleColorMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </colorModeContext.Provider>
  );
};

export const useColorMode = (): ColorModeContext => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const context = useContext(colorModeContext)!;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!context) {
    throw new Error("useColorMode must be used within a ColorModeContext");
  }
  return context;
};
