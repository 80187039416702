// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useQuery } from "@tanstack/react-query";

import { BURN_ADDRESS } from "../../utils/constant";
import { indexer } from "../../utils/indexer";

export function useDomainSubdomainsCount({ domain }: { domain: string }) {
  const { data: total = 0, isLoading: isTotalLoading } = useQuery<number | undefined>({
    queryKey: ["domains-subdomains-count", domain],
    placeholderData: (x: number | undefined) => x,
    enabled: !!domain,
    staleTime: 60 * 1000,
    queryFn: async () => {
      try {
        const totalRes = await indexer.get_domain_subdomains_count({
          burn_address: BURN_ADDRESS,
          domain,
        });

        return totalRes.current_aptos_names_aggregate.aggregate?.count ?? 0;
      } catch (e) {
        console.warn(e);
        return 0;
      }
    },
  });

  return { total, loading: isTotalLoading };
}
