// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { WarningAmberRounded } from "@mui/icons-material";
import { Chip, ChipProps, Tooltip, useTheme } from "@mui/material";
import { useMemo } from "react";

import { useIsDesktop } from "../../../context/hooks/useIsDesktop";
import { getExpirationStatus } from "../../../utils/getExpirationStatus";
import { AptosName, AptosNamesSearch } from "../../../utils/types";

interface ExpirationChipProps {
  ansRecord: AptosName | AptosNamesSearch;
  size?: ChipProps["size"];
}

export const ExpirationChip = ({ ansRecord, size }: ExpirationChipProps) => {
  const theme = useTheme();
  const isDesktop = useIsDesktop();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const expiration_status = getExpirationStatus(ansRecord.expiration);

  const label = useMemo<string | undefined>(() => {
    if (expiration_status === "in_grace") return "expired";
    if (expiration_status === "expires_soon") return "expiring soon";
  }, [expiration_status]);

  const color = useMemo<ChipProps["color"] | undefined>(() => {
    if (expiration_status === "in_grace") return "error";
    if (expiration_status === "expires_soon") return "warning";
  }, [expiration_status]);

  const tooltip = useMemo<string | undefined>(() => {
    if (expiration_status === "in_grace") {
      return "Renew name within 30 days of expiry to restore function. If not renewed, name will become available to others for registration.";
    }
    if (expiration_status === "expires_soon") {
      return "Name expiring within 90 days. Renew registration to maintain ownership.";
    }
  }, [expiration_status]);

  if (ansRecord.subdomain || !label) return null;

  return (
    <Tooltip title={tooltip ?? ""}>
      <Chip
        label={isDesktop ? label : undefined}
        color={color}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={() => {}}
        variant="outlined"
        size={size}
        icon={<WarningAmberRounded />}
        sx={{
          border: "none",
          "&>span:empty": {
            display: "none",
          },
          [theme.breakpoints.down("md")]: {
            "&>svg": {
              marginLeft: "0px!important",
              marginRight: "0px!important",
            },
          },
        }}
      />
    </Tooltip>
  );
};
