// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { isValidDomainName } from "../../utils";
import { AptosName } from "../../utils/types";
import { useDomain } from "./useDomain";

interface ManageDomainState {
  loading: boolean;
  domain?: AptosName | null | undefined;
  subdomain?: AptosName | null | undefined;
}

export function useManageDomainData(
  owner: string,
  domainName: string,
  subdomainName?: string,
): ManageDomainState {
  const isValid =
    isValidDomainName(domainName) && subdomainName ? isValidDomainName(subdomainName) : true;

  const domainRes = useDomain({
    owner,
    domain: domainName,
    enabled: isValid,
  });
  const subdomainRes = useDomain({
    owner,
    domain: domainName,
    subdomain: subdomainName,
    enabled: isValid && !!subdomainName?.length,
  });

  const loading = domainRes.isLoading || subdomainRes.isLoading;

  return {
    domain: domainRes.data,
    // TODO: There is an issue with react-query (I think) where if you
    // have the same query key and the query is disabled, it will still
    // serve the cached result. This is a workaround for now.
    subdomain: subdomainName?.length ? subdomainRes.data : undefined,
    loading,
  };
}
