// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import constate from "constate";
import React, { useMemo } from "react";

export interface ConfirmProps {
  title: string;
  description: JSX.Element | string;
  yes: Omit<ButtonProps, "onClick">;
  no?: Omit<ButtonProps, "onClick">;
}

interface ContextState {
  open: boolean;
  confirmState: ConfirmProps;
  resolveConfirmation?: (val: boolean) => void;
}

const constateResult = constate(() => {
  const [state, setState] = React.useState<ContextState>({
    open: false,
    confirmState: {
      title: "",
      description: "",
      yes: {
        children: "Confirm",
        variant: "contained",
      },
    },
  });

  return {
    open: state.open,
    confirmState: state.confirmState,

    showConfirm: (alert: ConfirmProps) => {
      return new Promise<boolean>((resolve) => {
        setState({
          ...state,
          open: true,
          confirmState: alert,
          resolveConfirmation: resolve,
        });
      });
    },

    onYes: () => {
      state.resolveConfirmation?.(true);
      setState({
        ...state,
        open: false,
        resolveConfirmation: undefined,
      });
    },

    onNo: () => {
      state.resolveConfirmation?.(false);
      setState({
        ...state,
        open: false,
        resolveConfirmation: undefined,
      });
    },
  };
});

const Provider = constateResult[0];

function ConfirmWrapper() {
  const { confirmState, open, onNo, onYes } = useConfirm();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const styles = useMemo<SxProps<Theme>>(() => {
    if (fullScreen) return {};

    return {
      minWidth: "400px",
    };
  }, [fullScreen]);

  return (
    <Dialog
      open={open}
      onClose={onNo}
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          padding: 2,
          paddingTOp: 1,
          paddingBottom: 2,
        },
      }}
      sx={styles}
    >
      <DialogTitle align="center">{confirmState.title}</DialogTitle>
      <DialogContent sx={styles}>
        <DialogContentText
          align={typeof confirmState.description === "string" ? "center" : "inherit"}
        >
          {confirmState.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{}}>
        {confirmState.no && (
          <Button
            {...confirmState.no}
            onClick={() => {
              onNo();
            }}
            sx={{
              ...(confirmState.no.sx || {}),
              flex: "1 1 auto",
            }}
          />
        )}
        <Button
          {...confirmState.yes}
          onClick={() => {
            onYes();
          }}
          sx={{
            ...(confirmState.yes.sx || {}),
            flex: "1 1 auto",
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

export const useConfirm = constateResult[1];

export const ConfirmProvider = ({ children }: React.PropsWithChildren<object>) => {
  return (
    <Provider>
      <ConfirmWrapper />
      {children}
    </Provider>
  );
};
