// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

/* eslint-disable @typescript-eslint/no-non-null-assertion */

export const explorerURL = "https://explorer.aptoslabs.com";
// TODO - change address once contract lands
export const CONTRACT_ADDRESS: string = process.env.REACT_APP_CONTRACT_ADDRESS!;
export const DOMAIN_REGISTER_MAX_YEARS = 1;

// RENEWALS CONSTANTS
// TODO: This should be an .env variable
export const BULK_MIGRATION_CONTRACT_ADDRESS =
  "0xf50e0bb52c52bd66b5f542f867418c63181f6d9eaa869008525beb553d97fb7d";
export const RENEWAL_PAGE_LIMIT = 5;
export const EXPIRATION_CUTOFF = 1709855999000;
export const EXPIRES_SOON_MONTHS_WINDOW = 3;
export const RENEWAL_MONTHS_WINDOW = 6;
export const ONE_YEAR_IN_SECONDS = 31536000;
export const ONE_DAY_IN_SECONDS = 86400;
// TODO: 2592000 is hardcoded on chain. We should be fetching this from the contract
export const GRACE_PERIOD_IN_DAYS = 2592000 / 60 / 60 / 24;

export const BEGINNING_OF_TIME = new Date("1970");
export const END_OF_TIME = new Date("3000");

// TODO: Ensure this is the same burn address for every network, and is a constant
export const BURN_ADDRESS: string = process.env.REACT_APP_BURN_ADDRESS!;

export enum SubdomainExpirationPolicy {
  Independent = 0,
  FollowsDomain = 1,
}
