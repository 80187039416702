// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { Box, Card, CardContent, Container, Divider, Stack, Typography } from "@mui/material";
import { orange } from "@mui/material/colors";
import { useParams } from "react-router-dom";

import { DomainName } from "../../../../components/DomainName";
import { SearchInput } from "../../../../components/SearchInput";
import { useDocumentTitle } from "../../../../context/hooks/setDocumentTitle";
import { extractAptFromDomainName } from "../../../../utils";
import { DomainCost } from "./DomainCost";
import { RegisterButton } from "./RegisterButton";

interface DomainNameURLParams {
  domain: string;
}

export function DomainNameRegister(): JSX.Element {
  const { domain } = useParams() as unknown as DomainNameURLParams;
  useDocumentTitle(`Register ${domain}.apt - Aptos Names`);
  const { connected } = useWallet();
  const domainName = extractAptFromDomainName(domain);

  return (
    <>
      <Container>
        <Box sx={{ mt: "5rem" }}>
          <SearchInput />
          <Card sx={{ mt: "5rem" }}>
            <CardContent>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                spacing={4}
                divider={<Divider variant="inset" orientation="horizontal" sx={{ mb: 0 }} />}
              >
                <DomainName domain={domainName} text="Available" />
                <Box sx={{ flexGrow: 1 }} />
                <DomainCost domainName={domain} />
                <Stack justifyContent="center">
                  <RegisterButton domainName={domainName} years={1} />
                  {!connected && (
                    <Typography color={orange[500]}>
                      Please connect a wallet to register the domain
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </>
  );
}
