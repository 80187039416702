// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { AptosNamesLayout } from "./layout";
import { Homepage } from "./pages/(home)/page";
import { DocumentationPage } from "./pages/about/documentation/page";
import { FAQPage } from "./pages/about/faq/page";
import { ManagePage } from "./pages/account/[domain]/[subdomain]/manage/page";
import { AccountPage } from "./pages/account/page";
import { DomainSearchPage } from "./pages/domain-search/page";
import { useSendAnalytic } from "./utils/sendAnalytic";

export function AptosNamingServiceRoutes() {
  const { wallet } = useWallet();
  const sendAnalytic = useSendAnalytic();

  useEffect(() => {
    const name = wallet?.name;
    if (name) {
      sendAnalytic("wallet_connected", { wallet_name: name });
    }
  }, [wallet?.name]);

  return (
    <AptosNamesLayout>
      <Routes>
        {/* Search */}
        <Route path="/domain-search/:domain" element={<DomainSearchPage />} />
        <Route path="/name/:domain" element={<DomainSearchPage />} />

        {/* Account Routes */}
        <Route path="/account" element={<AccountPage />} />
        <Route path="/account/:domain/manage" element={<ManagePage />} />
        <Route path="/account/:domain/:subdomain/manage" element={<ManagePage />} />

        {/* About */}
        <Route path="/about/documentation" element={<DocumentationPage />} />
        <Route path="/about/faq" element={<FAQPage />} />
        <Route path="/about/doc" element={<DocumentationPage />} />

        {/* HomePage */}
        <Route path="/" element={<Homepage />} />
        <Route path="*" element={<Homepage />} />
      </Routes>
    </AptosNamesLayout>
  );
}
