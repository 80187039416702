// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Box, CircularProgress, Modal } from "@mui/material";

interface LoadingModalProps {
  open: boolean;
}

export function LoadingModal({ open }: LoadingModalProps) {
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      >
        <CircularProgress />
      </Box>
    </Modal>
  );
}
