// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { CheckCircle } from "@mui/icons-material";
import { Chip, ChipProps, Tooltip } from "@mui/material";

import { useIsDesktop } from "../../../context/hooks/useIsDesktop";
import { AptosName } from "../../../utils/types";

interface PrimaryNameChipProps {
  ansRecord: AptosName;
  size?: ChipProps["size"];
}

export const PrimaryNameChip = ({ ansRecord, size }: PrimaryNameChipProps) => {
  const isDesktop = useIsDesktop();

  if (!ansRecord.is_primary) return null;

  const name = [ansRecord.subdomain, ansRecord.domain].filter((x) => x).join(".");
  const tooltip = `${name}.apt is the default name for this wallet across the Aptos ecosystem.`;

  return (
    <Tooltip title={tooltip}>
      <Chip
        label={isDesktop ? "primary name" : "primary"}
        size={size}
        sx={{ border: "none" }}
        icon={size !== "small" ? <CheckCircle color="inherit" fontSize="small" /> : <span />}
      />
    </Tooltip>
  );
};
