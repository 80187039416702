// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { BURN_ADDRESS, RENEWAL_PAGE_LIMIT } from "../../utils/constant";
import { indexer } from "../../utils/indexer";
import { makeFetchAll } from "../../utils/makeFetchAll";
import { parseCurrentAptosName } from "../../utils/parseIndexerNames";
import { AptosName, CurrentAptosName } from "../../utils/types";
import { useDomainSubdomainsCount } from "./useGetDomainSubdomainsCount";

export function useDomainSubdomains({ domain }: { domain: string }) {
  const [page, setPage] = useState(0);

  const { total, loading: totalLoading } = useDomainSubdomainsCount({ domain });

  const { data: subdomains = [], isLoading } = useQuery({
    queryKey: ["domains-subdomains", domain, page],
    placeholderData: (x: Array<AptosName> | undefined) => x,
    enabled: !!domain,
    staleTime: 60 * 1000,
    queryFn: async () => {
      try {
        const res = await indexer.get_domain_subdomains({
          domain,
          burn_address: BURN_ADDRESS,
          offset: page * RENEWAL_PAGE_LIMIT,
          limit: RENEWAL_PAGE_LIMIT,
        });

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const names = (res.current_aptos_names ?? []) as Array<CurrentAptosName>;
        return names.map(parseCurrentAptosName);
      } catch (e) {
        console.warn(e);
        return [];
      }
    },
  });

  function changePage(i: number) {
    if (isLoading) return;
    // Make sure the page is above 0 and below the total number of pages
    const clampedPage = Math.min(Math.max(0, i), Math.round(RENEWAL_PAGE_LIMIT / total));
    setPage(clampedPage);
  }

  const fetchAll = makeFetchAll(async (offset) => {
    const res = await indexer.get_domain_subdomains({
      domain,
      burn_address: BURN_ADDRESS,
      offset,
      limit: RENEWAL_PAGE_LIMIT,
    });

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const names = (res.current_aptos_names ?? []) as Array<CurrentAptosName>;
    return names.map(parseCurrentAptosName);
  });

  return {
    subdomains,
    loading: isLoading || totalLoading,
    fetchAll,
    page,
    changePage,
    total,
  };
}
