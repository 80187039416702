// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { RENEWAL_MONTHS_WINDOW } from "./constant";
import { getExpirationStatus } from "./getExpirationStatus";
import { AptosName } from "./types";

export function isRenewable(domain: AptosName): boolean {
  const status = getExpirationStatus(domain.expiration);

  const inRenewWindow = new Date();
  inRenewWindow.setMonth(inRenewWindow.getMonth() + RENEWAL_MONTHS_WINDOW);

  return status === "in_grace" || domain.expiration < inRenewWindow;
}
