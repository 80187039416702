// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Box, Button, IconButton, Link, Popover, Typography, useTheme } from "@mui/material";
import React from "react";

import { useIsDesktop } from "../context/hooks/useIsDesktop";
import { grey } from "../themes/colors/aptosColorPalette";
import { walletAddressEllipsis } from "../utils";
import { explorerURL } from "../utils/constant";

interface HashButtonProps {
  address: string;
}

export function HashButton({ address }: HashButtonProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const isDesktop = useIsDesktop();

  const hashExpand = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const hashCollapse = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Button
        sx={{
          textTransform: "none",
          backgroundColor: theme.palette.mode === "dark" ? grey[700] : grey[100],
          display: "flex",
          borderRadius: 1,
          color: "inherit",
          fontSize: { xs: "0.856em", md: "1em" },
          padding: {
            xs: "0.15rem 0.5rem",
            md: "0.15rem 0.5rem 0.15rem 1rem",
          },
          "&:hover": {
            backgroundColor: theme.palette.mode === "dark" ? grey[800] : grey[100],
          },
        }}
        aria-describedby={id}
        onClick={hashExpand}
        endIcon={isDesktop && <ChevronRightRoundedIcon sx={{ opacity: "0.75", m: 0 }} />}
      >
        {walletAddressEllipsis(address, isDesktop ? "medium" : "small")}
      </Button>

      <Popover
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{
          overflow: "scroll",
          ".MuiPaper-root": { boxShadow: "none" },
          "&.MuiModal-root .MuiBackdrop-root": {
            transition: "none!important",
            backgroundColor:
              theme.palette.mode === "dark" ? "rgba(18,22,21,0.5)" : "rgba(255,255,255,0.5)",
          },
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={hashCollapse}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: theme.palette.mode === "dark" ? grey[700] : grey[100],
            px: 2,
            py: "0.15rem",
            fontSize: "14px",
            overflow: "scroll",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Link href={`${explorerURL}/account/${address}`} target="_blank" color="inherit">
            {address}
          </Link>

          {isDesktop && (
            <IconButton
              aria-label="collapse hash"
              onClick={hashCollapse}
              sx={{
                ml: 1,
                mr: 0,
                p: 0,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
            >
              <ChevronLeftRoundedIcon sx={{ opacity: "0.5" }} />
            </IconButton>
          )}
        </Typography>
      </Popover>
    </Box>
  );
}
