// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";

import { CopyToClipboard } from "./CopyToClipboard";

interface DomainNameProps {
  domain: string;
  text?: string;
}

export function DomainName({ domain, text }: DomainNameProps): JSX.Element {
  return (
    <Box>
      <Stack direction="row">
        <Typography sx={{ typography: { xs: "h4" }, wordBreak: "break-all" }} mr={2}>
          {domain}.apt
        </Typography>
        <CopyToClipboard textToCopy={domain ? `${domain}.apt` : ""}>
          <IconButton>
            <ContentCopyIcon sx={{ opacity: "0.5" }} />
          </IconButton>
        </CopyToClipboard>
      </Stack>
      {text && <Typography color={text === "Available" ? green.A400 : red[400]}>{text}</Typography>}
    </Box>
  );
}
