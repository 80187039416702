// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Button, Tooltip } from "@mui/material";

import { LoadingModal } from "../../../../components/LoadingModal";
import { useDomainActions } from "../../../../context/hooks/useDomainActions";

interface ClearButtonProps {
  domainName: string;
  disable: boolean;
  callback?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export function ClearButton({ domainName, disable, callback }: ClearButtonProps) {
  const { clearTargetAddress, transactionInProcess } = useDomainActions();

  const onClearClick = async () => {
    const res = await clearTargetAddress(domainName);
    if (res.success) {
      callback && callback(undefined);
    }
  };

  return (
    <>
      <LoadingModal open={transactionInProcess} />
      {disable ? (
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={onClearClick}
          sx={{ minWidth: "6.5rem" }}
        >
          Clear
        </Button>
      ) : (
        <Tooltip title="You can only Clear the Target Address if you are the registerer and logged into your wallet">
          <span>
            <Button disabled={true} variant="contained" size="large" sx={{ minWidth: "6.5rem" }}>
              Clear
            </Button>
          </span>
        </Tooltip>
      )}
    </>
  );
}
